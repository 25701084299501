<template>
	<el-form class="page" :model="linije" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
				<el-form-item :label="$t('linije.naziv')">
					<el-input v-model="linije.naziv" readonly>
						<template slot="append">
							<el-button @click="linije.naziv_custom = linije.naziv">
								<i class="fas fa-angle-double-right"></i>
							</el-button>
						</template>
					</el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
				<el-form-item :label="$t('linije.nazivCustom')" prop="naziv_custom">
					<el-input v-model="linije.naziv_custom" :maxLength="100"/>
				</el-form-item>
			</el-col>
		</el-row>


		<el-row justify="space-around">
			<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
				<el-form-item>
					<el-card class="box-card">
						<el-row slot="header" class="clearfix">
							<el-row :gutter="10">
								<el-col :xs="18" :sm="18" :md="18" :lg="20" :xl="20">
									<select-mesta v-model="selectMesto" ref="mesto" class="width-full"/>
								</el-col>
								<el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
									<el-button size="small" @click="dodajMesto(selectMesto)">
										<el-icon class="el-icon-circle-plus"/>
										{{ $t('global.dodaj') }}
									</el-button>
								</el-col>
							</el-row>
						</el-row>
						<h3>{{$t('linije.redosledMesta')}}</h3>
						<draggable v-if="linije.mesta.length" v-model="linije.mesta" @change="promenaMesta">
							<transition-group>
								<div v-for="(mesto, index) in linije.mesta" :key="mesto.id_mesta">
									<el-tag class="cursor-move width-full">
										<el-row :gutter="10">
											<el-col :xs="1" :span="2">
												<i v-if="linije.mesta.length === 1" class="el-icon-caret-right"></i>
												<i v-else-if="index === 0" class="el-icon-caret-bottom"></i>
												<i v-else-if="linije.mesta.length === index + 1" class="el-icon-caret-top"></i>
												<i v-else class="el-icon-d-caret"></i>
											</el-col>
											<el-col :xs="10" :span="20">
												{{mesto.naziv_izvorno}}
											</el-col>
											<el-col :xs="1" :span="2">
												<i class="el-icon-delete cursor-click hover-red" @click="ukloniMestoIzLinije(index)"></i>
											</el-col>
										</el-row>
									</el-tag>
								</div>
							</transition-group>
						</draggable>
					</el-card>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button v-loading="onSubmitLoading" :disabled="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
import draggable from 'vuedraggable';
export default {
	name: 'linije-edit',
	data() {
		return {
			onSubmitLoading: false,
			fullscreenLoading: true,
			id: '',
			formCreate: '',
			linije: {
				naziv: '',
				naziv_custom: '',
				mesta: [],
				aktivno: 'NE'
			},
			selectMesto: null,
			rules: {
				naziv_custom: [{ required: true, max: 100, trigger: 'blur', message: this.$t('message.obavezno') }]
			}
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('linije.linije')
		});

		this.$utils('stopLoadingAfter', [this.getLinije()]);
	},
	methods: {
		getLinije() {
			if (!this.formCreate) {
				return this.$get('linije', { id: this.id }).then(data => {
					this.linije = data;
				});
			}
		},
		onSubmit() {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.linije);

				if (this.formCreate)
					this.$save('linije', data);
				else
					this.$update('linije', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		},
		ukloniMestoIzLinije(index) {
			this.linije.mesta.splice(index, 1);
		},
		dodajMesto(id) {
			if (!id)
				return false;

			let postoji = _.findIndex(this.linije.mesta, function(o) {
				return o.id_mesta === id;
			});

			if (postoji >= 0) {
				// Alert obavestenje
				this.$alert(this.$t('alert.podatakPostoji'), '', {
					confirmButtonText: this.$t('global.uredu')
				});
			}
			else {
				let mesto = this.$refs.mesto.getMesto(id);
				this.linije.mesta.push({
					id_mesta: mesto.id,
					naziv_izvorno: mesto.naziv_izvorno
				});
				this.selectMesto = null;

				// Ako se zadnje mesto promenilo
				this.promenaMesta();
			}
		},
		promenaMesta() {
			// Setovanje imena
			this.nazivLinije();
		},
		nazivLinije() {
			if (this.linije.mesta && this.linije.mesta.length > 0) {
				let prvo = _.head(this.linije.mesta)['naziv_izvorno'];
				let zadnje = _.last(this.linije.mesta)['naziv_izvorno'];

				this.linije.naziv = prvo + ' - ' + zadnje;
			}
		}
	},
	components: {
		draggable,
		selectMesta: require('../../components/select/mesta').default
	}
};
</script>
